import axios from "axios";
import React, { useEffect } from "react";
import { saveAs } from "file-saver";

const LinkIcon: React.FC<{
  icon: string;
  target: string;
  name: string;
  id: number;
  profile_id: number;
  user_id: number;
  from_template: boolean;
}> = ({ icon, target, name, id, user_id, profile_id, from_template }) => {
  const saveVcard = async (vcfTemplate: string) => {
    // Save the VCF file to the user's computer
    const blob = new Blob([vcfTemplate], {
      type: "text/x-vcard;charset=utf-8",
    });
    await saveAs(blob, `contact.vcf`);
  };
  return (
    <div
      onClick={async () => {
        try {
          await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/api/link_tap`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id,
              id,
              profile_id,
              from_template,
            }),
          });
        } catch (err) {}
      }}
    >
      {name === "Contact" ? (
        <button
          onClick={() => {
            saveVcard(target);
          }}
          className="col-span-1 flex flex-col  justify-start items-center drop-shadow-lg"
        >
          <img
            src={icon}
            className="w-[5.5rem] h-[5.5rem] mb-2 aspect-square object-cover rounded-xl"
            alt=""
          />
          <h3 className="text-center font-semibold text-xs">{name}</h3>
        </button>
      ) : (
        <a
          href={target}
          target="_blank"
          className="col-span-1 flex flex-col  justify-start items-center drop-shadow-lg"
        >
          <img
            src={icon}
            className="w-[5.5rem] h-[5.5rem] mb-2 aspect-square object-cover rounded-xl"
            alt=""
          />
          <h3 className="text-center font-semibold text-xs">{name}</h3>
        </a>
      )}
    </div>
  );
};

export default LinkIcon;
