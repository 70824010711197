import React from "react";

const HighlightIcon: React.FC<{
  name: string;
  target: string;
  icon: string;
  description: string;
  id: number;
  user_id: number;
  profile_id: number;
  from_template: boolean;
}> = ({
  name,
  target,
  icon,
  description,
  id,
  user_id,
  profile_id,
  from_template,
}) => {
  return (
    <div
      className="w-full bg-gray-100"
      onClick={async () => {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_SERVER}/api/link_tap`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                user_id,
                id,
                profile_id,
                from_template,
              }),
            }
          );
          const data = await res.json();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      <div className=" py-3 px-12  w-full flex items-center bg-[#F5F5F4]">
        <a
          href={target}
          target="_blank"
          rel="noreferrer"
          className="flex justify-center items-center grid-cols-3 gap-x-4"
        >
          <div>
            <img
              src={icon}
              alt=""
              className="min-w-[5.5rem] max-w-[5.5rem] min-h-[5.5rem] max-h-[5.5rem]  aspect-square object-cover col-span-1 mr- drop-shadow-xl rounded-xl "
            />
          </div>
          <div className="flex flex-col col-span-2  w-full h-full overflow-hidden text-ellipsis justify-center items-start whitespace-nowrap">
            <h3 className=" text-lg font-semibold capitalize tracking-wider">
              {name}
            </h3>
            <h3 className="w-full text-sm text-[#414141] font-semibold">
              {description}
            </h3>
          </div>
        </a>
      </div>
    </div>
  );
};

export default HighlightIcon;
